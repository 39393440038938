<template>
  <div class="videoplayer">
    <video controls :src="url" autoplay @ended="onEnd()"></video>
  </div>
</template>

<script>
export default {
  name: "Video",
  props: {
    url: String,
  },
    methods: {
    onEnd: function () {
        if (document.fullscreenElement) {
    document.exitFullscreen()
  }
    }
  }
};


/*document
  .getElementById("Video_player")
  .addEventListener("ended", myHandler, false);
function myHandler(e) {
  // What you want to do after the event
  console.log(e);
  this.$router.push({ path: "page/1" });
}*/
</script>

<style scoped lang="scss">
.videoplayer {
  height: 100vh;

  video {
    width: 100%;
    height: 100%;
    background-color: black;
  }
}
</style>